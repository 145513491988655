import React, {
    useState, useRef
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import _Input from '../Input';
import _Textarea from '../Textarea';
import _Button from '../Button';
import _Loader from '../Loader';

const Loader = styled(_Loader)`
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    width: 30px;
`;

const Hidden = styled.div`
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
`;

const Input = styled(_Input)`
    flex: 1;
    margin: 10px;

    ${({ theme }) => theme.media.mobile`
      margin: 10px 0px;
    `}
`;

const Button = styled(_Button)`
    margin-left: auto;
    cursor: pointer;
    min-width: 160px;

    margin-right: auto;
`;

const Textarea = styled(_Textarea)`
    flex: 1;
    margin: 10px;

    ${({ theme }) => theme.media.mobile`
      margin: 10px 0px;
    `}
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  width: 50%;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet`
    width: 100%;
    padding: 20px 0px;
  `}
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const Message = styled.span`
  font-size: 16px;
  transition: opacity 0.250s 0.250s ease, color 0s -500ms;

  color: ${({
        theme, type
    }) => type === 'ok' ? theme.colors.valid.hex : theme.colors.error.hex};

  opacity: ${({ visible }) => visible ? 1 : 0};
  margin-left: auto;
  margin-right: auto;
`;

const encode = (data) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

const ContactForm = () => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [botfield, setBotfield] = useState('');
    const [returnMessage, setReturnMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const nameRef = useRef();
    const surnameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const validEmail = (emailCheck) => {
    // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailCheck).toLowerCase());
    };

    const handleBotfield = ({ target }) => {
        setBotfield(target.value);
    };

    const resetForm = () => {
        setName('');
        setSurname('');
        setCompany('');
        setPhone('');
        setEmail('');
        setMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let shouldSend = !botfield;

        if (!name) {
            nameRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }
        if (!surname) {
            surnameRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }
        if (!email) {
            emailRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        } else if (!validEmail(email)) {
            emailRef.current.onError('error_format');
        }
        if (!message) {
            messageRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }

        if (shouldSend) {
            setIsLoading(true);
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': 'contact',
                    name,
                    surname,
                    company,
                    phone,
                    email,
                    message,
                    'bot-field': botfield
                })
            }).then(() => {
                resetForm();
                setReturnMessage({
                    visible: true,
                    type: 'ok',
                    value: 'message_sent'
                });
                setTimeout(() => {
                    setReturnMessage({
                        visible: false,
                        type: 'ok',
                        value: 'message_sent'
                    });
                }, 8000);
            }).catch(() => {
                setReturnMessage({
                    visible: true,
                    type: 'error',
                    value: 'message_failed'
                });
                setTimeout(() => {
                    setReturnMessage({
                        visible: false,
                        type: 'error',
                        value: 'message_failed'
                    });
                }, 8000);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <FormContainer
            name="contact"
            data-netlify="true"
            netlify-honeypot="bot-field">
            <Hidden>
                <input
                    value={botfield}
                    onChange={handleBotfield}
                    name="bot-field" />
            </Hidden>
            <Row>
                <Input ref={nameRef}
                    id="name"
                    value={name}
                    onChange={setName}
                    label={t('name')}
                    placeholder={t('name')}
                    required />
                <Input ref={surnameRef}
                    id="surname"
                    value={surname}
                    onChange={setSurname}
                    label={t('surname')}
                    placeholder={t('surname')}
                    required />
            </Row>
            <Row>
                <Input id="company"
                    value={company}
                    onChange={setCompany}
                    label={t('company_field')}
                    placeholder={t('company_field')} />
                <Input id="phone"
                    value={phone}
                    onChange={setPhone}
                    label={t('phone')}
                    placeholder="01 23 45 67 89"
                    type="tel" />
            </Row>
            <Row>
                <Input ref={emailRef}
                    id="email"
                    value={email}
                    onChange={setEmail}
                    label={t('email')}
                    placeholder="label@email.com"
                    type="email"
                    required />
            </Row>
            <Row>
                <Textarea ref={messageRef}
                    id="message"
                    value={message}
                    onChange={setMessage}
                    label={t('message')}
                    placeholder="Message..."
                    required />
            </Row>
            <Row>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Button onClick={handleSubmit}>
                        {t('send')}
                    </Button>
                )}
            </Row>
            <Row>
                <Message type={returnMessage.type}
                    visible={Boolean(returnMessage.visible)}>
                    {t(returnMessage.value)}
                </Message>
            </Row>
        </FormContainer>
    );
};

export default React.memo(ContactForm);
