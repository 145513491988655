import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonStyled = styled.button`
    background: ${({ theme }) => theme.colors.mainBlue.hex};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white.hex};
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    padding: 10px 25px;
    margin: 15px;
    transition: background 0.4s ease;
    border: none;

    &:hover {
      background: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.9);
    }
`;

const Button = ({
    children, onClick, className
}) => (
    <ButtonStyled onClick={onClick}
        className={className}
        type="button">
        {children}
    </ButtonStyled>
);

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.any
};

Button.defaultProps = {
    className: '',
    children: null
};
export default React.memo(Button);
