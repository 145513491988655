import React, {
    forwardRef, useImperativeHandle, useState
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    margin-bottom: 10px;
`;

const StyledTextArea = styled.textarea`
    background-color: ${({ theme }) => theme.colors.grey.hex};
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    padding: 15px 10px;
    border-radius: 4px;
    border: none;
    font-weight: 200;
    letter-spacing: 0.5px;
    min-height: 100px;
    resize: none;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px rgba(${({ theme }) => theme.colors.mainBlue.rgb}, .5);
    }
    
    &::placeholder {
        color: ${({ theme }) => theme.colors.darkGrey.hex};
        font-weight: 200;
    }

    ${({
        error, theme
    }) => error ? `
    box-shadow: 0 0 0 2px rgba(${theme.colors.error.rgb}, .8);
  ` : null}
`;

const Error = styled.span`
  font-size: 12px;
  font-weight: 200;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error.hex};
`;

const Textarea = forwardRef(({
    label, placeholder, className, required, onChange, value, id
}, ref) => {
    const { t } = useTranslation();
    const [error, setError] = useState(null);

    useImperativeHandle(ref, () => ({onError(err) {
        setError(err);
    }}));

    const handleOnChange = ({ target }) => {
        onChange(target.value);
        if (error) {
            setError(null);
        }
    };

    return (
        <Container className={className}>
            <Label htmlFor={id}>
                {label}
                {required ? ' *' : null}
            </Label>
            <StyledTextArea
                id={id}
                name={id}
                error={Boolean(error)}
                value={value}
                onChange={handleOnChange}
                placeholder={placeholder}
                required={required}
            />
            <Error>
                {t(error)}
            </Error>
        </Container>
    );
});

Textarea.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    id: PropTypes.string.isRequired
};

Textarea.defaultProps = {
    label: '',
    placeholder: '',
    onChange: () => {},
    className: '',
    required: false
};

export default Textarea;
