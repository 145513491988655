import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import { PageTitle } from './../../components/Text';
import ContactForm from './../../components/contact/ContactForm';

import CompanyInformation from './../../components/contact/CompanyInformation';

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;


  ${({ theme }) => theme.media.tablet`
    width: calc(100% - 20px);
  `}
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 380px;
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 20px;


  ${({ theme }) => theme.media.tablet`
    width: auto;
    padding-right: 0px;
  `}
`;

const Contact = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('contact_meta_title')}
                </title>
                <meta name="description"
                    content={t('contact_meta_description')} />
            </Helmet>
            <Container>
                <PageTitle>
                    {t('contact')}
                </PageTitle>
                <Row>
                    <Block>
                        <CompanyInformation />
                        <IframeContainer>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2824.4836547444934!2d4.927010615868642!3d44.933834976695316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f55708fc181f1d%3A0xc53c1143acf5d9f1!2s9%20Cours%20Alexandre%20Borodine%2C%2026000%20Valence!5e0!3m2!1sfr!2sfr!4v1580855094251!5m2!1sfr!2sfr"
                                width="100%"
                                title="map"
                                height="100%"
                                frameBorder="0"
                                allowFullScreen=""
                            ></iframe>
                        </IframeContainer>
                    </Block>
                    <ContactForm />
                </Row>
            </Container>
        </Layout>
    );
};

export default React.memo(Contact);
