import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MainText } from '../Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const Block = styled.div``;

const MainTextBlue = styled(MainText)`
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    font-size: 20px;
    font-weight: bold;
`;

const MainTextStyled = styled(MainText)`
    padding: 5px 15px;
`;

const MainTextBold = styled(MainText)`
    padding: 0px;
    display: inline;
    font-weight: bold;
    margin-right: 4px;
`;

const CompanyInformation = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <MainTextBlue>
                {t('headquarter')}
            </MainTextBlue>
            <Row>
                <Block>
                    <MainTextStyled>
                        SAS Beaumont
                    </MainTextStyled>
                    <MainTextStyled>
                        9 Cours Borodine
                    </MainTextStyled>
                    <MainTextStyled>
                        26000 Valence
                    </MainTextStyled>
                </Block>
                <Block>
                    <MainTextStyled>
                        <MainTextBold as="span">
                            {t('phone_small')}
                            . :
                        </MainTextBold>
                        04 75 43 27 65
                    </MainTextStyled>
                    <MainTextStyled>
                        <MainTextBold as="span">
                            {t('email')}
                            . :
                        </MainTextBold>
                        steatitebeaumont@wanadoo.fr
                    </MainTextStyled>
                </Block>
            </Row>
        </Container>
    );
};

export default React.memo(CompanyInformation);
