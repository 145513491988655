import React, {
    forwardRef, useImperativeHandle, useState, useRef
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    margin-bottom: 10px;

    ${({ type }) => type === 'file' ? `
    visibility: hidden;
    opacity: 0;
    height: 0px;
    padding: 0;
    margin: 0;
  ` : null}
`;

const StyledInput = styled.input`
    background-color: ${({ theme }) => theme.colors.grey.hex};
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    padding: 15px 10px;
    border-radius: 4px;
    border: none;
    font-weight: 200;
    letter-spacing: 0.5px;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px rgba(${({ theme }) => theme.colors.mainBlue.rgb}, .5);
    }
    
    &::placeholder {
        color: ${({ theme }) => theme.colors.darkGrey.hex};
        font-weight: 200;
    }

    ${({
        error, theme
    }) => error ? `
      box-shadow: 0 0 0 2px rgba(${theme.colors.error.rgb}, .8);
    ` : null}

    ${({ type }) => type === 'file' ? `
      visibility: hidden;
      opacity: 0;
      height: 0px;
      padding: 0;
      margin: 0;
    ` : null}
`;

const Error = styled.span`
  font-size: 12px;
  font-weight: 200;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.error.hex};
`;

const Input = forwardRef(({
    label, placeholder, className, type, required, onChange, value, id
}, ref) => {
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
        onError(err) {
            setError(err);
        },
        inputRef: inputRef.current
    }));

    const handleOnChange = ({ target }) => {
        if (type === 'file') {
            onChange([...target.files]);
        } else {
            onChange(target.value);
        }
        if (error) {
            setError(null);
        }
    };

    return (
        <Container className={className}>
            <Label type={type}
                htmlFor={id}>
                {label}
                {required ? ' *' : null}
            </Label>
            <StyledInput
                id={id}
                ref={inputRef}
                error={Boolean(error)}
                name={id}
                value={value}
                type={type}
                onChange={handleOnChange}
                placeholder={placeholder}
                required={required}
            />
            <Error>
                {t(error)}
            </Error>
        </Container>
    );
});

Input.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    id: PropTypes.string.isRequired
};

Input.defaultProps = {
    value: null,
    label: '',
    placeholder: '',
    onChange: () => {},
    className: '',
    type: 'text',
    required: false
};

export default Input;
