import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const rotate = keyframes`
  0% {
    transform: rotate(0deg); 
  }
  50% {
    transform: rotate(180deg); 
  }
  100% {
    transform: rotate(360deg); 
  }
`;

const LoaderStyled = styled.div`
    height: 100%;
    width: 100%;
    border-top: 6px solid ${({ theme }) => theme.colors.mainBlue.hex};
    border-right: 6px solid rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.5);
    border-bottom: 6px solid rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.5);
    border-left: 6px solid ${({ theme }) => theme.colors.mainBlue.hex};
    border-radius: 50%;

    animation: 1.5s ${rotate} infinite linear;
`;

const Loader = ({ className }) => (
    <LoaderStyled className={className} />
);

Loader.propTypes = {className: PropTypes.string};

Loader.defaultProps = {className: ''};

export default React.memo(Loader);
